import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { CruiseSearchFormProps, CruiseSearchFormFlexModuleResult } from "./typings";
import { UitkSpacing } from "@egds/react-core/spacing";
import { FieldChangeCallback } from "@egds/react-core/form";
import {
  CruiseSearchForm as SearchForm,
  CruiseSearchFormOptions,
  CruiseSearchFormInputs,
} from "@shared-ui/retail-search-tools-product";

/* istanbul ignore next */
const handleFieldChange: FieldChangeCallback = () => undefined;

/**
 * Wrapper for `@shared-ui/search-tools-cruises` SearchForm
 * Powered by https://flexmanager.test.expedia.biz/app/legacy/moduleStore/show/752
 */
export const CruiseSearchForm = withStores(
  "flexModuleModelStore",
  "context",
  "page"
)(
  observer((props: CruiseSearchFormProps) => {
    const { templateComponent, flexModuleModelStore, page } = props;

    /* istanbul ignore if */
    if (!templateComponent || !flexModuleModelStore) {
      return null;
    }

    const { metadata } = templateComponent;
    const { id } = metadata;
    const model = flexModuleModelStore.getModel(id) as CruiseSearchFormFlexModuleResult | null;

    /* istanbul ignore next */
    if (!model) {
      return null;
    }

    const searchFormProps: CruiseSearchFormOptions & { inputs: CruiseSearchFormInputs } = {
      inputs: {},
      onFieldChange: handleFieldChange,
      redirectToSearchResultOnSubmit: true,
      additionalAdapterFields: { xPageId: page?.pageId ?? "" },
      onOpenLocationField: undefined,
      updateLatLongOnSelection: undefined,
      onOpenDatesField: undefined,
      essLogger: undefined,
    };

    return (
      <UitkSpacing
        margin={{
          small: {
            block: "three",
            inline: "three",
          },
        }}
      >
        <section id={id} data-testid="cruise-search-form">
          <SearchForm {...searchFormProps} />
        </section>
      </UitkSpacing>
    );
  })
);

CruiseSearchForm.displayName = "CruiseSearchForm";

export default CruiseSearchForm;
